//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { UserModel } from '@/modules/auth/user-model';
import { i18n } from '@/i18n';
// import * as firebase from 'firebase/app';
// import { routerAsync } from '@/app-module';

const { fields } = UserModel;

export default {
  name: 'app-signin-page',

  data() {
    return {
      isTypingEmail: false, 
      isTypingPassword: false, 
      isPwd: true,
      rules: {
        email: fields.email.forFormRules(),
        password: fields.password.forFormRules(),
        rememberMe: fields.rememberMe.forFormRules(),
      },
      model: {
        rememberMe: true,
      },
    };
  },
  computed: {
    ...mapGetters({
      loading: 'auth/loading',
      // signedIn: 'auth/signedIn'
    }),
    fields() {
      return fields;
    },
  },
  methods: {
    ...mapActions ({
      doSigninSocial: 'auth/doSigninSocial',
      doSigninWithEmailAndPassword: 'auth/doSigninWithEmailAndPassword',
    }),
    i18n(code) {
      return i18n(code);
    },
    onInputChange() {
      this.model.email
        ? (this.isTypingEmail = true)
        : (this.isTypingEmail = false);
      
      this.model.password
        ? (this.isTypingPassword = true)
        : (this.isTypingPassword = false);  
    },

    async doSubmit() {
      // try {
      //   await this.$refs.form.validate();
      // } catch (error) {
      //   return;
      // }

      await this.doSigninWithEmailAndPassword({
        email: this.model.email,
        password: this.model.password,
        rememberMe: this.model.rememberMe,
      });
    },
  },
};
